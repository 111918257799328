<template>
  <Layout>
    <PageHeader :title=title :items="itemHeader"></PageHeader>
    <div class="row for-sm">
      <div class="col-xxl-9 col-md-9">
        <div class="row">
          <div class="col-xxl-12 col-md-12" v-if="state.facility">
            <facility-information
                :is-disabled-button="state.status.label === FacilitiesRequestStatus.APPROVED || state.status.label === FacilitiesRequestStatus.REJECTED"
                ref="facilityInformation"
                @reload="loadFacility" :is-my-facility="false"
                :facility="state.facility"
                :update-with-endpoint="true"
                :facility-id="state.facilityId.toString()"
                :facility-title="'施設申請詳細'"
                @onUpdateWidthEndpoint="handleUpdateFacilityInfo"
                @handleOnlyValidateFacility="setParamFacility"
                :only-validate="onlyValidate"
                :compare-object="state.isCompareChange"
                :origin-facility="state.originFacility"
            />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room
                :is-disabled-button="state.status.label === FacilitiesRequestStatus.APPROVED || state.status.label === FacilitiesRequestStatus.REJECTED"
                ref="SAUNA_DRIED"
                @onUpdateWidthEndpoint="handleUpdateRoom"
                :is-room-request="true"
                room-sub-type="SAUNA_DRIED"
                :is-my-facility="false" :facility="state.facility"
                :facility-id="state.facilityId.toString()"
                :update-with-endpoint="true"
                :only-validate="onlyValidate"
                @handleOnlyValidateRoom="setParamRoom"
                :compare-object="state.isCompareChange"
                :origin-facility="state.originFacility"
                :is-facility-application="true"
            />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room
                :is-disabled-button="state.status.label === FacilitiesRequestStatus.APPROVED || state.status.label === FacilitiesRequestStatus.REJECTED"
                ref="SAUNA_WET"
                @onUpdateWidthEndpoint="handleUpdateRoom"
                :is-room-request="true"
                room-sub-type="SAUNA_WET"
                :is-my-facility="false"
                :facility="state.facility"
                :facility-id="state.facilityId.toString()"
                :update-with-endpoint="true"
                :only-validate="onlyValidate"
                @handleOnlyValidateRoom="setParamRoom"
                :compare-object="state.isCompareChange"
                :origin-facility="state.originFacility"
                :is-facility-application="true"
            />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room
                :is-disabled-button="state.status.label === FacilitiesRequestStatus.APPROVED || state.status.label === FacilitiesRequestStatus.REJECTED"
                ref="SAUNA_RESTED"
                @onUpdateWidthEndpoint="handleUpdateRoom"
                :is-room-request="true"
                room-sub-type="SAUNA_RESTED"
                :is-my-facility="false"
                :facility="state.facility"
                :facility-id="state.facilityId.toString()"
                :update-with-endpoint="true"
                :only-validate="onlyValidate"
                @handleOnlyValidateRoom="setParamRoom"
                :compare-object="state.isCompareChange"
                :origin-facility="state.originFacility"
                :is-facility-application="true"
            />
          </div>
        </div>
      </div>
      <div class="col-xxl-3 col-md-3">
        <div class="row">
          <div class="col-xxl-12 col-md-12">
            <div class="row table-component">
              <div class="col-xxl-12 col-md-12">
                <div class="card p-3">
                  <div class="flex align-items-center line-header" style="column-gap: 10px" v-if="state.status.label === FacilitiesRequestStatus.WAITING">
                    <div style="width: 106px">申請情報</div>
                    <div class="d-flex">
                      <div class="row">
                        <div class="col-xl-6 col-12 py-1">
                          <b-button variant="success" :disabled="!state.isAfterPublicTime || isDisabledChangeStatus" style="width: 84px" @click="handleChangeStatus(FacilitiesRequestStatus.APPROVED)" class="waves-effect waves-light">
                            承認
                          </b-button>
                        </div>
                        <div class="col-xl-6 col-12 py-1">
                          <b-button variant="danger" :disabled="isDisabledChangeStatus" style="width: 84px" @click="handleChangeStatus(FacilitiesRequestStatus.REJECTED)" class="waves-effect waves-light">
                            拒否
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="py-3 d-flex flex-column" style="row-gap: 12px">
                    <div class="flex" style="column-gap: 10px">
                      <div class="w-106">申請タイプ：</div>
                      <span
                          :class="`badge bg-${state.type.color}-subtle text-${state.type.color}`"
                          class="btn"
                          style="font-size: 11px; width: 109px"
                      >
                        {{ $t(`facilityApplication.requestType.${state.type.label}`)}}
                      </span>
                    </div>
                    <div class="flex" style="column-gap: 10px">
                      <div class="w-106">申請ステータス：</div>
                      <span
                          :class="`badge bg-${state.status.color}-subtle text-${state.status.color}`"
                          class="btn"
                          style="font-size: 11px; width: 109px">{{$t(`facilityApplication.status.${state.status.label}`)}}</span>
                    </div>
                    <div class="flex" style="column-gap: 10px">
                      <div class="w-106">開業日：</div>
                      <span>{{publicTime !== null ? publicTime.format('YYYY年MM月DD日') : ''}}</span>

                    </div>
                    <div v-if="!state.isAfterPublicTime" class="mt-2 text-time">
                      開業日まで承認できません。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-12 col-md-12">
            <div class="row table-component">
              <div class="col-xxl-12 col-md-12">
                <div class="card p-3">
                  <div class="flex align-items-center line-header pt-0" style="column-gap: 10px">
                    <div>申請者情報</div>
                  </div>
                  <div class="py-3 d-flex flex-column" style="row-gap: 12px" v-if="state.facility">
                    <div class="flex" style="column-gap: 10px">
                      <div class="w-106">申請者名：</div>
                      <div>{{state.createdBy.name}}</div>
                    </div>
                    <div class="flex" style="column-gap: 10px">
                      <div class="w-106">メール：</div>
                      <div>{{state.createdBy.email}}</div>

                    </div>
                    <div class="flex" style="column-gap: 10px">
                      <div class=" w-106">生年月日：</div>
                      <div>{{state.createdBy.birthDay ? moment(state.createdBy.birthDay).format('YYYY-MM-DD') : ''}}</div>
                    </div>
                    <div class="flex" style="column-gap: 10px">
                      <div class="w-106">電話番号：</div>
                      <div>{{state.createdBy.phone}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-12 col-md-12" v-if="state.facility">
            <FacilityFilterInformation
                :is-disabled-button="state.status.label === FacilitiesRequestStatus.APPROVED || state.status.label === FacilitiesRequestStatus.REJECTED"
                ref="facilityFilterInformation"
                @reload="loadFacility"
                @onUpdateWidthEndpoint="handleUpdateFacilityService"
                :facility="state.facility"
                :is-my-facility="false"
                :facility-id="state.facilityId.toString()"
                :update-with-endpoint="true"
                :compare-object="state.isCompareChange"
                :origin-facility="state.originFacility"
            />
          </div>
          <div class="col-xxl-12 col-md-12">
            <Settings
                :is-disabled-button="state.status.label === FacilitiesRequestStatus.APPROVED || state.status.label === FacilitiesRequestStatus.REJECTED"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
import Layout from "@/layouts/main.vue";
import FacilityInformation from "@/views/setting/facility-management/components/FacilityInformation.vue";
import Settings from "@/views/setting/facility-management/components/Settings.vue";
import FacilityFilterInformation from "@/views/setting/facility-management/components/FacilityFilterInformation.vue";
import Room from "@/views/setting/facility-management/components/Room.vue";
import {onMounted, reactive, ref} from "vue";
import i18n from "@/i18n";
import { useRoute } from 'vue-router'
import {facilityService} from "@/services";
import {FacilitiesRequestStatus, ColorStatus, ColorType, FacilitiesRequestType} from "@/constants";
import store from "@/state/store";
import Swal from "sweetalert2";
import {cloneDeep, get} from "lodash";
import moment from "moment";
import {isDisabled} from "bootstrap/js/src/util";

export default {
  name: "updateFacilityTablet",
  components: {
    Layout,
    PageHeader,
    FacilityInformation,
    Settings,
    FacilityFilterInformation,
    Room
  },
  props: {
    facilityInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(prop) {
    const router = useRoute();
    const title = ref(i18n.global.t('facility.details'));
    const isDisabledChangeStatus = ref(false);
    const itemHeader = reactive([
      {
        text: i18n.global.t('facility.management'),
        href: "/admin/setting/facilities/application",
      },
      {
        text: i18n.global.t('facility.details'),
        active: true,
      },
    ]);
    let state = reactive({
      facility: prop.facilityInfo,
      facilityId: '',
      loadingButton: false,
      status: {
        label: '',
        color: ''
      },
      type: {
        label: '',
        color: ''
      },
      requestDescription: '',
      createdBy: {},
      validate: {
        formFacility: false,
        SAUNA_DRIED: true,
        SAUNA_WET: true,
        SAUNA_RESTED: true
      },
      payload: {
        facility: {},
        service: []
      },
      isAfterPublicTime: true,
      isCompareChange: false,
      originFacility: {}
    });
    const facilityInformation = ref(null);
    const SAUNA_DRIED = ref(null);
    const SAUNA_WET = ref(null);
    const SAUNA_RESTED = ref(null);
    const facilityFilterInformation = ref(null);

    const loadFacility = () => {
      console.log('loadFacility')
    }

    onMounted(() => {
      getFacility()
    })

    const publicTime = ref(null);
    const onlyValidate = ref(false);
    const getFacility = async () => {
      const { facilityId } = router.params;
      try {
       const response = await facilityService.getFacilityAppById(facilityId);
       const userTmp = response.createdBy;
       if (userTmp && userTmp.username) {
        userTmp.username =userTmp.username;
       }
       if (userTmp && userTmp.email) {
        userTmp.email = userTmp.email;
       }
       state.createdBy = userTmp;
       state.status.label = get(response, "requestStatus", '');
       state.status.color = ColorStatus[get(response, "requestStatus", '')];
       state.type.label = get(response, "requestType", '');
       state.type.color = ColorType[get(response, "requestType", '')];

       state.requestDescription = get(response, 'requestDescription', '');
       if (get(response, 'facilities[0].publicTime')) {
         publicTime.value = moment(get(response, 'facilities[0].publicTime'));

         const temp = moment(publicTime.value).format('YYYY-MM-DD');
         state.isAfterPublicTime = moment(temp) <= moment();
       }

       state.isCompareChange = get(response, 'requestType', '') === FacilitiesRequestType.UPDATE;

       if (state.isCompareChange) {
         state.originFacility = get(response, 'originFacility', {})
       }

       if (response.facilities) {
         state.facility = {
           ...response.facilities[0],
           facilityServices: response.facilityServices,
           fixedDayOff: response.fixedDayOff,
           rooms: response.rooms,
         }
       } else {
         isDisabledChangeStatus.value = true
       }

       state.facilityId = response.id
      } catch (e) {
        console.log(e, '======')
      }
    }

    const handleChangeStatus = async (value) => {
      state.validate = {
        formFacility: false,
        SAUNA_DRIED: true,
        SAUNA_WET: true,
        SAUNA_RESTED: true
      };

      if (isDisabledChangeStatus.value) {
        return;
      }
      onlyValidate.value = true;
      if (publicTime.value !== null && !publicTime.value.isBefore(moment(), 'hour') && value === FacilitiesRequestStatus.APPROVED) return;
      const messageConfirm = value === FacilitiesRequestStatus.APPROVED ? i18n.global.t('facilityApplication.confirmApprove', { name: state.facility.name }) : i18n.global.t('facilityApplication.confirmReject', { name: state.facility.name })
      const time = value === FacilitiesRequestStatus.APPROVED ? 500 : 0;
      if (time > 0) {
        facilityInformation.value.onSubmit();
        SAUNA_DRIED.value.onSubmit();
        SAUNA_WET.value.onSubmit();
        SAUNA_RESTED.value.onSubmit();
        facilityFilterInformation.value.onSubmit();
      }
      setTimeout(async () => {
        if (Object.values(state.validate).some(item => !item) && time  > 0) {
          onlyValidate.value = false;
          return;
        }
        try {
          state.loadingButton = true;
          const accept = await Swal.fire({
            title:`<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${messageConfirm}</h2>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: i18n.global.t('yes'),
            cancelButtonText: i18n.global.t('cancel'),
          });
          if (accept.value) {
            const tempPayload = cloneDeep(state.payload);
            const {facility, service} = tempPayload;
            const {fixedDayOff} = tempPayload.facility;
            const SAUNA_DRIED = get(tempPayload, 'SAUNA_DRIED', []);
            const SAUNA_RESTED = get(tempPayload, 'SAUNA_RESTED', []);
            const SAUNA_WET = get(tempPayload, 'SAUNA_WET', []);

            const payloadFacilityInfo = cloneDeep(facility);
            delete payloadFacilityInfo.fixedDayOff;
            delete payloadFacilityInfo.facilityServices;

            const tempRoom = SAUNA_DRIED.concat(SAUNA_RESTED, SAUNA_WET);

            const payloadFacilityApp = {
              facilities: [
                payloadFacilityInfo
              ],
              fixedDayOff: fixedDayOff,
              facilityServices: service,
              rooms: tempRoom
            }

            await facilityService.changeStatusFacilityApp(router.params.facilityId, {...payloadFacilityApp, status: value});
            const messageSuccess = value === FacilitiesRequestStatus.APPROVED ? i18n.global.t('facilityApplication.approveSuccess') : i18n.global.t('facilityApplication.rejectSuccess');
            store.commit('settings/SET_POPUP', {
              show: true,
              type: 'success',
              message: messageSuccess
            });

            await getFacility();
          }
        } catch (e) {
          let message = get(e, 'response.data.message', '');
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'error',
            message: message
          });
        } finally {
          state.loadingButton = false;
          onlyValidate.value = false;
        }
      }, time);
    }

    const setParamFacility = (value) => {
      const {validate} = value;

      state.validate.formFacility = validate;
    }

    const setParamRoom = (value) => {
      const {validate, type} = value;
      state.validate[type] = validate;
    }

    const changeState = (value, field) => {
      state.payload = {
        ...state.payload,
        [field]: value
      }
    }

    return {
      state,
      title,
      itemHeader,
      publicTime,
      FacilitiesRequestStatus,
      facilityInformation,
      SAUNA_DRIED,
      SAUNA_WET,
      SAUNA_RESTED,
      onlyValidate,
      facilityFilterInformation,
      isDisabledChangeStatus,
      changeState,
      setParamFacility,
      loadFacility,
      handleChangeStatus,
      moment,
      setParamRoom
    }
  },

  methods: {
    isDisabled,
    showPopup(type = 'success', message = this.$t('msg.saved')) {
      store.commit('settings/SET_POPUP', {
        show: true,
        type: type,
        message: message
      });
    },

    async handleUpdateFacilityInfo(value) {
      const payload = value.payload;
      delete payload.rooms;
      if (this.onlyValidate) {
        this.changeState(payload, 'facility')
      } else {
        try {
          this.$refs.facilityInformation.setLoadingAction(true);
          const {fixedDayOff} = payload;
          await facilityService.updateFacilityApplicationById(this.$route.params.facilityId, {facilities: [payload], fixedDayOff});
          this.showPopup();
        } catch (e) {
          const message = get(e, 'response.data.message') || e.message || '';
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'error',
            message: message
          });
        } finally {
          this.$refs.facilityInformation.setLoadingAction(false)
        }
      }
    },

    async handleUpdateFacilityService(value) {
      if (this.onlyValidate) {
        this.changeState(value, 'service')
      } else {
        try {
          this.$refs.facilityFilterInformation.setLoadingAction(true);
          const payload = {facilityServices: value};
          await facilityService.updateFacilityApplicationById(this.$route.params.facilityId, payload);
          this.showPopup()
        } catch (e) {
          const message = get(e, 'response.data.message') || e.message || '';
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'error',
            message: message
          });
        } finally {
          this.$refs.facilityFilterInformation.setLoadingAction(false);
        }
      }
    },

    async handleUpdateRoom(value, type) {
      if (this.onlyValidate) {
        this.changeState(value, `${type}`);
      } else {
        const payload = {rooms: value};
        try {
          this.$refs[type].changeLoadingAction(true);
          payload['subType'] = type;
          await facilityService.updateFacilityApplicationById(this.$route.params.facilityId, payload);
          this.showPopup()
        } catch (e) {
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'error',
            message: e.message
          });
        } finally {
          this.$refs[type].onLoadData();
          this.$refs[type].changeLoadingAction(false)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.w-106 {
  width: 106px;
  min-width: 106px;
}
@media only screen and (max-width: 600px) {
  .for-sm {
    flex-direction: column-reverse;
  }
}

.line-header {
    border-bottom: 1px solid var(--vz-border-color) !important;
    padding-bottom: 10px;
  }
.text-time {
  color: #F31717;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.bg-success-subtle {
  background-color: #d9f5f0 !important;
}
.bg-warning-subtle {
  background-color: #fff5da !important;
}
.bg-danger-subtle {
  background-color: #fde8e4 !important;
}
.bg-secondary-subtle {
  background-color: #ededf0 !important;
}
</style>
